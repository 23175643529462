import { customAlphabet } from 'nanoid'
import styled from 'styled-components'
import { Editor } from './editor'

// https://github.com/CyberAP/nanoid-dictionary#nolookalikes-safe
const urlSafeNanoid = customAlphabet('6789BCDFGHJKLMNPQRTWbcdfghjkmnpqrtwz', 8)

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.p`
  margin-bottom: 1.125rem;
`

const A = styled.a`
  text-decoration: unset;

  &:hover {
    text-decoration: underline;
  }
`

export const App = () => {
  const docId = new URLSearchParams(window.location.search).get('id')

  if (docId === 'new') {
    window.location.search = `id=${urlSafeNanoid()}`
  }

  return !docId ? (
    <Container>
      <Title>Collaborative text editor</Title>
      <A href={`/?id=${urlSafeNanoid()}`}>Open new doc</A>
    </Container>
  ) : (
    <Editor docId={docId} />
  )
}

import MonacoEditor from '@monaco-editor/react'
import * as monacoLib from 'monaco-editor'
import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import * as awarenessProtocol from 'y-protocols/awareness.js'
import { WebrtcProvider } from 'y-webrtc'
import * as Y from 'yjs'
import { AbsolutePosition } from 'yjs/dist/src/internals'
import { MonacoBinding } from '../lib/y-monaco'

const colors = [
  '#0558F9',
  '#D82020',
  '#22AC00',
  '#FFCE0B',
  '#C8258C',
  '#F87426',
  '#14AAFF',
  '#FF5A50',
  '#9548F5',
  '#8CFF60',
  '#FF50BF',
  '#FF9533',
  '#7000DF',
  '#FFED3E',
]

const getColor = (idx: number) => {
  return colors[idx % colors.length]
}

const transparent = (color: string): string => color + '55'

const menuWidthRem = 10

const Menu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${menuWidthRem}rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  font-size: 14px;

  border-right: 0.5px solid #e5e5e5;
  /* Dark */
  /* background-color: #1e1e1e; */
`

const SelectLanguage = styled.select`
  position: absolute;
  display: block;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  width: ${menuWidthRem - 1}rem;
  color: inherit;
  background-color: inherit;
  outline: none;
  font: inherit;
`

const User = styled.div<{ $clickable: boolean }>`
  font-family: Menlo, Monaco, 'Courier New', monospace;
  padding: 0.5rem 0.75rem;

  ${p =>
    p.$clickable &&
    css`
      color: inherit;
      text-decoration: inherit;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    `}
`

const Circle = styled.span`
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  margin-bottom: 0px;
  margin-right: 0.5rem;
`

const StyledMonacoEditor = styled(MonacoEditor)`
  width: 100%;
  height: 100%;

  /* Dark */
  /* .mtk1.mtk1 {
    color: #fafafa;
  } */

  .yRemoteSelection {
  }

  .yRemoteSelectionHead {
    position: absolute;
    height: 100%;
    box-sizing: border-box;
    border-right: ${colors[0]} solid 2px;
    border-top: ${colors[0]} solid 2px;
    border-bottom: ${colors[0]} solid 2px;
  }

  ${colors
    .map(
      (color, index) => `
        .yRemoteSelection${index} {
          background-color: ${transparent(color)};
        }

        .yRemoteSelectionHead${index} {
          border-color: ${color};
        }

        .yRemoteSelectionHead${index}::after {
          border-color: ${color};
        }
      `
    )
    .join('\n')}
`

const setUserNameLocalStorage = (): string => {
  const userName = window.localStorage.getItem('user.name')

  let newUserName: string | null = ''

  while (userName ? newUserName === '' : !newUserName) {
    newUserName = window.prompt(
      userName ? 'Change your name:' : 'Enter your name:',
      userName || 'Anonymous'
    )
  }

  newUserName = newUserName || userName || 'Anonymous'

  window.localStorage.setItem('user.name', newUserName)

  return newUserName
}

export const getUserName = (): string => {
  if (typeof window === 'undefined') return ''
  const userName = window.localStorage.getItem('user.name')
  return userName ?? ''
}

type ClientID = number
type UserData = { clientID: ClientID; name?: string; timeJoined?: number }

export const Editor = ({ docId }: { docId: string }): JSX.Element => {
  const awarenessStatesRef = useRef<Map<ClientID, Record<string, unknown>> | null>(null)
  const yDocRef = useRef<Y.Doc | null>(null)
  const providerRef = useRef<WebrtcProvider | null>(null)

  const [users, setUsers] = useState<UserData[]>([])

  const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    yDocRef.current?.getMap('data').set('language', e.target.value)
  }

  return (
    <>
      <Menu>
        {users
          .sort((u1, u2) => {
            if (u1.timeJoined && u2.timeJoined) {
              return u1.timeJoined > u2.timeJoined ? 1 : -1
            }
            return u1.clientID > u2.clientID ? 1 : -1
          })
          .map((user, idx) => (
            <User
              key={user.clientID}
              style={{ backgroundColor: transparent(getColor(idx)) }}
              $clickable={user.clientID === yDocRef.current?.clientID}
              onClick={() => {
                if (user.clientID !== yDocRef.current?.clientID) return
                const name = setUserNameLocalStorage()
                providerRef.current?.awareness.setLocalStateField('name', name)
              }}
            >
              <Circle style={{ backgroundColor: getColor(idx) }} />
              {user.name || 'Anonymous'}
              {user.clientID === yDocRef.current?.clientID && ' (you)'}
            </User>
          ))}

        <SelectLanguage onChange={changeLanguage} id='language'>
          <option value='plaintext'>Plaintext</option>
          <option value='javascript'>JavaScript</option>
          <option value='python'>Python</option>
          <option value='java'>Java</option>
          <option value='kotlin'>Kotlin</option>
          <option value='go'>Go</option>
          <option value='cpp'>C++</option>
          <option value='rust'>Rust</option>
        </SelectLanguage>
      </Menu>

      <StyledMonacoEditor
        wrapperProps={{
          style: {
            position: 'fixed',
            top: '0',
            right: '0',
            bottom: '0',
            left: `${menuWidthRem}rem`,
          },
        }}
        options={{
          minimap: { enabled: true },
          fontSize: 14,
          // automaticLayout: true,
        }}
        onMount={(editor, monaco) => {
          // @ts-ignore
          window.editor = editor

          const getIdIndex = (id: number): number =>
            !awarenessStatesRef.current
              ? 0
              : Array.from(awarenessStatesRef.current.keys()).sort().indexOf(id)

          const yDoc = new Y.Doc()
          yDocRef.current = yDoc

          const defineTheme = () => {
            const myIdIndex = getIdIndex(yDoc.clientID)

            monaco.editor.defineTheme('my-theme', {
              base: 'vs',
              rules: [],
              inherit: true,
              colors: {
                'editorCursor.foreground': getColor(myIdIndex),
                'editor.selectionBackground': transparent(getColor(myIdIndex)),
                'editor.inactiveSelectionBackground': transparent(getColor(myIdIndex)),
                'editor.selectionHighlightBackground': '#00000000',
              },
            })
          }

          defineTheme()

          monaco.editor.setTheme('my-theme')

          const randomPrefix = '8j6C8pgH'

          const provider = new WebrtcProvider(
            `${randomPrefix}-${docId}`,
            yDoc,
            // @ts-ignore
            {
              password: 'efe605f8-fc1a-4078-ae2d-356289fd0206', // Encrypt messages
              awareness: new awarenessProtocol.Awareness(yDoc),
            }
          )
          providerRef.current = provider

          const yText = yDoc.getText('monaco')

          const yMap = yDoc.getMap('data')
          yMap.observe(() => {
            const model = editor.getModel()
            if (!model) return
            const language = yMap.get('language')
            monaco.editor.setModelLanguage(model, language)
            ;(document.getElementById('language') as HTMLSelectElement).value = language
          })

          provider.awareness.setLocalStateField('name', getUserName())
          provider.awareness.setLocalStateField('timeJoined', Date.now())

          provider.awareness.on('change', () => {
            awarenessStatesRef.current = provider.awareness.getStates()

            setUsers(
              [...awarenessStatesRef.current].map(([clientID, state]) => ({
                clientID,
                name: state.name as string,
              }))
            )

            defineTheme()
          })

          const editorModel = editor.getModel()

          if (editorModel === null) throw new Error()

          const cursorDecoration = (
            monacoModel: monacoLib.editor.ITextModel,
            headAbs: AbsolutePosition,
            anchorAbs: AbsolutePosition,
            state: Record<string, unknown>,
            clientID: number
          ) => {
            const index = getIdIndex(clientID)

            let start, end, afterContentClassName, beforeContentClassName
            if (anchorAbs.index < headAbs.index) {
              start = monacoModel.getPositionAt(anchorAbs.index)
              end = monacoModel.getPositionAt(headAbs.index)
              afterContentClassName = `yRemoteSelectionHead yRemoteSelectionHead${index}`
              beforeContentClassName = null
            } else {
              start = monacoModel.getPositionAt(headAbs.index)
              end = monacoModel.getPositionAt(anchorAbs.index)
              afterContentClassName = null
              beforeContentClassName = `yRemoteSelectionHead yRemoteSelectionHead${index}`
            }

            return {
              range: new monacoLib.Range(
                start.lineNumber,
                start.column,
                end.lineNumber,
                end.column
              ),
              options: {
                className: `yRemoteSelection yRemoteSelection${index}`,
                afterContentClassName,
                beforeContentClassName,
                hoverMessage: [{ value: state.name }],
              },
            }
          }

          new MonacoBinding(
            yText,
            editorModel,
            new Set([editor]),
            provider.awareness,
            cursorDecoration
          )
        }}
      />
    </>
  )
}
